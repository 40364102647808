import React, { useMemo } from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
// import { NewsFilter } from "./news-filter/NewsFilter";
import { NewsTable } from "./news-table/NewsTable";
// import { NewsGrouping } from "./news-grouping/NewsGrouping";
import { useNewsUIContext } from "./NewsUIContext";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as actions from "../_redux/newsActions";
import { set } from "lodash";

export function NewsCard() {
  const newsUIContext = useNewsUIContext();
  const { currentState } = useSelector((state) => ({ currentState: state.news }), shallowEqual);
  // News Redux state
  const dispatch = useDispatch();

  const newsUIProps = useMemo(() => {
    const { pageNumber } = currentState;
    if (pageNumber > 1) {
      set(newsUIContext, "queryParams.pageNumber", pageNumber);
      dispatch(actions.savePageNumber(1));
    }
    return {
      ids: newsUIContext.ids,
      queryParams: newsUIContext.queryParams,
      setQueryParams: newsUIContext.setQueryParams,
      addNewButtonClick: newsUIContext.addNewButtonClick,
      openDeleteNewsDialog: newsUIContext.openDeleteNewsDialog,
      openEditNewPage: newsUIContext.openEditNewPage,
      openUpdateNewsStatusDialog: newsUIContext.openUpdateNewsStatusDialog,
      openFetchNewsDialog: newsUIContext.openFetchNewsDialog,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsUIContext]);

  return (
    <Card>
      <CardHeader title="News list">
        <CardHeaderToolbar>
          <button type="button" className="btn btn-primary" onClick={newsUIProps.addNewButtonClick}>
            Add New
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <NewsTable />
      </CardBody>
    </Card>
  );
}
