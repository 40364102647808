// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { UserStatusCssClasses, UserStatusTitles } from "../../UsersUIHelpers";

export function StatusColumnFormatter(cellContent, row) {
	const getLabelCssClasses = () => {
		return `label label-lg label-light-${
			row.active ? UserStatusCssClasses[1] : UserStatusCssClasses[0]
		} label-inline`;
	};

	return (
		<span className={getLabelCssClasses()}>
			{row.active ? UserStatusTitles[1] : UserStatusTitles[0]}
		</span>
	);
}
