/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import * as actions from "../../_redux/newsActions";
import { useNewsUIContext } from "../NewsUIContext";

export function NewDeleteDialog({ id, show, onHide }) {
	// News UI Context
	const newsUIContext = useNewsUIContext();
	const newsUIProps = useMemo(() => {
		return {
			setIds: newsUIContext.setIds,
			queryParams: newsUIContext.queryParams,
		};
	}, [newsUIContext]);

	// News Redux state
	const dispatch = useDispatch();
	const { isLoading } = useSelector(
		(state) => ({ isLoading: state.news.actionsLoading }),
		shallowEqual
	);

	// if !id we should close modal
	useEffect(() => {
		if (!id) {
			onHide();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	// looking for loading/dispatch
	useEffect(() => {}, [isLoading, dispatch]);

	const deleteNew = () => {
		// server request for deleting new by id
		dispatch(actions.deleteNew(id)).then(() => {
			// refresh list after deletion
			dispatch(actions.fetchNews({
				limit: newsUIProps.queryParams.pageSize,
				page: newsUIProps.queryParams.pageNumber,
			}));
			// clear selections list
			newsUIProps.setIds([]);
			// closing delete modal
			onHide();
		});
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="example-modal-sizes-title-lg"
		>
			{isLoading && <ModalProgressBar variant="query" />}
			<Modal.Header closeButton>
				<Modal.Title id="example-modal-sizes-title-lg">New Delete</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!isLoading && (
					<span>Are you sure to permanently delete this new?</span>
				)}
				{isLoading && <span>New is deleting...</span>}
			</Modal.Body>
			<Modal.Footer>
				<div>
					<button
						type="button"
						onClick={onHide}
						className="btn btn-light btn-elevate"
					>
						Cancel
					</button>
					<> </>
					<button
						type="button"
						onClick={deleteNew}
						className="btn btn-delete btn-elevate"
					>
						Delete
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
