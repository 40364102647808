import { createSlice } from "@reduxjs/toolkit";

const initialNewsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  newForEdit: undefined,
  lastError: null,
  pageNumber: 1,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const newsSlice = createSlice({
  name: "news",
  initialState: initialNewsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // pageNumberUpdated
    pageNumberUpdated: (state, action) => {
      const { pageNumber } = action.payload;
      state.pageNumber = pageNumber;
    },
    // getNewById
    newFetched: (state, action) => {
      state.actionsLoading = false;
      state.newForEdit = action.payload.newForEdit;
      state.error = null;
    },
    // findNews
    newsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createNew
    newCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateNew
    newUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteNew
    newDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteNews
    newsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // newsUpdateState
    newsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
