import { createSlice } from "@reduxjs/toolkit";

const initialEventsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  eventForEdit: undefined,
  lastError: null,
  pageNumber: 1,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const eventsSlice = createSlice({
  name: "events",
  initialState: initialEventsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getEventById
    eventFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventForEdit = action.payload.eventForEdit;
      state.error = null;
    },
    // findEvents
    eventsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createEvent
    eventCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateEvent
    eventUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteEvent
    eventDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // pageNumberUpdated
    pageNumberUpdated: (state, action) => {
      const { pageNumber } = action.payload;
      state.pageNumber = pageNumber;
    },
    // deleteEvents
    eventsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // eventsUpdateState
    eventsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
