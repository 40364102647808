// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../_metronic/_partials/controls";

// Validation schema
const UserEditSchema = Yup.object().shape({
	username: Yup.string()
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Username is required"),
	password: Yup.string()
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Password is required"),
	email: Yup.string()
		.email("Invalid email")
		.required("Email is required"),
});

export function UserEditForm({ saveUser, user, actionsLoading, onHide }) {
	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={user}
				validationSchema={UserEditSchema}
				onSubmit={(values) => {
					saveUser(values);
				}}
			>
				{({ handleSubmit }) => (
					<>
						<Modal.Body className="overlay overlay-block cursor-default">
							{actionsLoading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							<Form className="form form-label-right">
								<div className="form-group row">
									{/* User Name */}
									<div className="col-lg-4">
										<Field
											name="username"
											component={Input}
											placeholder="User Name"
											label="User Name"
										/>
									</div>
									{/* Email */}
									<div className="col-lg-4">
										<Field
											type="password"
											name="password"
											component={Input}
											placeholder="Password"
											label="Password"
										/>
									</div>
									{/* Email */}
									<div className="col-lg-4">
										<Field
											type="email"
											name="email"
											component={Input}
											placeholder="Email"
											label="Email"
										/>
									</div>
								</div>
								<div className="form-group row">
									{/* Type */}
									<div className="col-lg-4">
										<Select name="roles" label="Role">
											<option value="admin">Admin</option>
											<option value="user">User</option>
										</Select>
									</div>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								onClick={onHide}
								className="btn btn-light btn-elevate"
							>
								Cancel
							</button>
							<> </>
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="btn btn-primary btn-elevate"
							>
								Save
							</button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</>
	);
}
