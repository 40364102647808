import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { usersSlice } from "../app/modules/Users/_redux/usersSlice";
import { newsSlice } from "../app/modules/News/_redux/newsSlice";
import { eventsSlice } from "../app/modules/Events/_redux/eventsSlice";
import { announcementsSlice } from "../app/modules/Announcements/_redux/announcementsSlice";
import { guidesSlice } from "../app/modules/Guides/_redux/guidesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  users: usersSlice.reducer,
  news: newsSlice.reducer,
  events: eventsSlice.reducer,
  announcements: announcementsSlice.reducer,
  guides: guidesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
