import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}api/cms/users/signin`;
export const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}api/cms/users/changepassword`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}api/auth/me`;

export function login(username, password) {
	return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
	return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
	return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function changePassword(params) {
	return axios.put(CHANGE_PASSWORD_URL, params);
}

export function getUserByToken() {
	// Authorization head should be fulfilled in interceptor.
	return axios.get(ME_URL);
}
