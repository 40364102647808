import * as requestFromServer from "./newsCrud";
import { newsSlice, callTypes } from "./newsSlice";

const { actions } = newsSlice;

export const fetchNews = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findNews(queryParams)
    .then((response) => {
      const {
        data,
        _metadata: { total_count },
      } = response.data.data;
      dispatch(actions.newsFetched({ totalCount: total_count, entities: data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find news";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNew = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.newFetched({ newForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getNewById(id)
    .then((response) => {
      const item = response.data.data;
      dispatch(actions.newFetched({ newForEdit: item }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find new";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteNew = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteNew(id)
    .then(() => {
      dispatch(actions.newDeleted());
    })
    .catch((error) => {
      error.clientMessage = "Can't delete new";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createNew = (newForCreation) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .createNew(newForCreation)
      .then((response) => {
        const { item } = response.data;
        dispatch(actions.newCreated({ item }));
        resolve(response);
      })
      .catch((error) => {
        error.clientMessage = "Can't create new";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });

export const savePageNumber = (pageNumber) => (dispatch) => {
  dispatch(actions.pageNumberUpdated({ pageNumber }));
};

export const updateNew = (item) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updateNew(item)
      .then((response) => {
        dispatch(actions.newUpdated());
        resolve(response);
      })
      .catch((error) => {
        error.clientMessage = "Can't update new";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });

export const updatePublished = (item) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updatePublished(item)
      .then((response) => {
        dispatch(actions.newUpdated());
        resolve(response);
      })
      .catch((error) => {
        error.clientMessage = "Can't update new";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });

export const updateNewsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForNews(ids, status)
    .then(() => {
      dispatch(actions.newsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update news status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadFile = (file) => () => {
  return requestFromServer.uploadFile(file);
};

export const deleteFile = (file) => () => {
  return requestFromServer.deleteFile(file);
};

export const deleteNews = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteNews(ids)
    .then(() => {
      dispatch(actions.newsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete news";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
