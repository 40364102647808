import axios from "axios";

export const PRODUCTS_URL = "api/news";
export const API_URL = process.env.REACT_APP_API_URL;

// CREATE =>  POST: add a new new to the server
export function createNew(data) {
  return axios.post(`${API_URL}api/cms/news/create`, data);
}

// READ
export function getAllNews() {
  return axios.get(PRODUCTS_URL);
}

export function getNewById(newId) {
  return axios.get(`${API_URL}api/cms/news/detail/${newId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findNews(queryParams) {
  return axios.post(`${API_URL}api/cms/news/getlist`, queryParams);
}

// UPDATE => PUT: update the procuct on the server
export function updateNew(data) {
  return axios.put(`${API_URL}api/cms/news/update`, data);
}

// UPDATE => PUT: update the procuct on the server
export function updatePublished(data) {
  return axios.put(`${API_URL}api/cms/news/update/published`, data);
}

// UPDATE => PUT: update the procuct on the server
export function uploadFile(file) {
  return axios.post(`${API_URL}upload`, file);
}

// UPDATE => PUT: update the procuct on the server
export function deleteFile(file) {
  return axios.delete(`${API_URL}file/${file}`);
}

// UPDATE Status
export function updateStatusForNews(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForNews`, {
    ids,
    status,
  });
}

// DELETE => delete the new from the server
export function deleteNew(newId) {
  return axios.delete(`${API_URL}api/cms/news/delete/${newId}`);
}

// DELETE News by ids
export function deleteNews(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteNews`, { ids });
}
