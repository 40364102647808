// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import * as actions from "../../_redux/newsActions";
import * as Yup from "yup";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { forEach, isObject, split } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;

// Validation schema
const NewEditSchema = Yup.object().shape({
	title: Yup.string().required("Title is required"),
	slug: Yup.string().required("URL is required"),
	description: Yup.string().required("Description is required"),
	cover_image_name: Yup.string(),
	tag: Yup.string(),
	html_content: Yup.string(),
	is_published: Yup.bool(),
});

export function NewEditForm({ new: dataNew, btnRef, saveNew }) {
	const dispatch = useDispatch();
	const [cover, setCover] = useState("");
	const [toolbar, setToolbar] = useState(null);
	const [tempImages, setTempImages] = useState({});
	const [isPreview, setIsPreview] = useState(false);
	const [htmlContent, setHtmlContent] = useState("");

	useEffect(() => {
		if (dataNew.cover_image_name) {
			setCover(dataNew.cover_image_name);
		}
		if (dataNew.html_content) {
			setHtmlContent(dataNew.html_content);
		}
	}, [dataNew]);

	const getCover = () => {
		if (!cover) {
			return "none";
		}

		if (isObject(cover)) {
			return `url(${URL.createObjectURL(cover)})`;
		}

		return `url(${API_URL}thumb/${cover})`;
	};

	const removeCover = () => {
		setCover("");
	};

	const deleteImageCKEditor = (item) => {
		const newImages = split(item.html_content, `${API_URL}file`).reduce(
			(res, item) => {
				if (item[0] === "/") {
					let tempImg = split(`${split(item, "/")[1]}`, `"`)[0];
					res[tempImg] = tempImg;
				}
				return res;
			},
			{}
		);

		if (dataNew.html_content) {
			const oldImages = split(
				dataNew.html_content,
				`${API_URL}file`
			).reduce((res, item) => {
				if (item[0] === "/") {
					let tempImg = split(`${split(item, "/")[1]}`, `"`)[0];
					res[tempImg] = tempImg;
				}
				return res;
			}, {});

			forEach(Object.values(oldImages), (img) => {
				if (!newImages[img]) {
					dispatch(actions.deleteFile(img));
				}
			});
		}

		if (Object.values(tempImages).length > 0) {
			forEach(Object.values(tempImages), (img) => {
				if (!newImages[img]) {
					dispatch(actions.deleteFile(img));
				}
			});
		}
	};

	const uploadAdapter = (loader) => {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const formData = new FormData();
					loader.file.then((file) => {
						formData.append("file", file);
						dispatch(actions.uploadFile(formData)).then(
							(res) => {
								const tempImg =
									res.data.data.images[0].filename;
								tempImages[tempImg] = tempImg;
								setTempImages(tempImages);
								resolve({
									default: `${API_URL}file/${tempImg}`,
								});
							},
							(err) => {
								reject(err);
							}
						);
					});
				});
			},
		};
	};

	const uploadPlugin = (editor) => {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	};

	return (
		<>
			<Modal
				show={isPreview}
				aria-labelledby="example-custom-modal-styling-title"
				dialogClassName="modal-90w preview-modal"
				onHide={() => setIsPreview(false)}
			>
				<Modal.Body>
					<div
						style={{ borderWidth: "0px !important" }}
						className="ck ck-view ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred"
						dangerouslySetInnerHTML={{
							__html: htmlContent,
						}}
					></div>
				</Modal.Body>
				<Modal.Footer>
					<button
						type="button"
						onClick={() => setIsPreview(false)}
						className="btn btn-primary btn-elevate"
					>
						Ok
					</button>
				</Modal.Footer>
			</Modal>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Formik
				enableReinitialize={true}
				initialValues={dataNew}
				validationSchema={NewEditSchema}
				onSubmit={(values) => {
					if (!cover) {
						return toast.error("Cover image is required");
					}

					if (!values.html_content) {
						return toast.error("Content is required");
					}

					deleteImageCKEditor(values);
					saveNew(values, cover);
				}}
			>
				{({ handleSubmit, handleChange, setFieldValue }) => (
					<>
						<Form className="form form-label-right">
							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									Cover Image
								</label>
								<div className="col-lg-9 col-xl-6">
									<div
										className="image-input image-input-outline"
										id="kt_profile_avatar"
										style={{
											backgroundImage: `url(${toAbsoluteUrl(
												"/media/users/blank.png"
											)}`,
										}}
									>
										<div
											className="image-input-wrapper"
											style={{
												backgroundImage: `${getCover()}`,
												width: "200px",
												height: "200px",
											}}
										/>
										<label
											className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
											data-action="change"
											data-toggle="tooltip"
											title=""
											data-original-title="Change avatar"
										>
											<i className="fa fa-pen icon-sm text-muted"></i>
											<input
												type="file"
												accept=".png, .jpg, .jpeg"
												onChange={(e) =>
													setCover(e.target.files[0])
												}
											/>
											<input
												type="hidden"
												name="profile_avatar_remove"
											/>
										</label>
										<span
											className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
											data-action="cancel"
											data-toggle="tooltip"
											title=""
											data-original-title="Cancel avatar"
										>
											<i className="ki ki-bold-close icon-xs text-muted"></i>
										</span>
										<span
											onClick={removeCover}
											className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
											data-action="remove"
											data-toggle="tooltip"
											title=""
											data-original-title="Remove avatar"
										>
											<i className="ki ki-bold-close icon-xs text-muted"></i>
										</span>
									</div>
									<span className="form-text text-muted">
										Allowed file types: png, jpg, jpeg.
									</span>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-4">
									<Field
										name="slug"
										component={Input}
										placeholder="URL"
										label="URL"
										onChange={handleChange}
									/>
								</div>
								<div className="col-lg-4">
									<Field
										name="title"
										component={Input}
										placeholder="Title"
										label="Title"
										onChange={handleChange}
									/>
								</div>
								<div className="col-lg-4">
									<Field
										name="description"
										component={Input}
										placeholder="Description"
										label="Description"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-lg-4">
									<Select name="tag" label="Tag" default="news">
										<option value="news">News</option>
										<option value="events">Events</option>
										<option value="guides">Guides</option>
										<option value="web3games">Web3games</option>
									</Select>
								</div>
								<div className="col-lg-8 d-flex justify-content-end align-items-center">
									<div
										className="btn btn-success btn-shadow-hover font-weight-bolder py-3"
										onClick={() => setIsPreview(true)}
									>
										Preview
									</div>
								</div>
							</div>
							<div className="form-group">
								<CKEditor
									onReady={(editor) => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);

										setToolbar(editor);
									}}
									onError={(error, { willEditorRestart }) => {
										if (willEditorRestart) {
											toolbar.ui.view.toolbar.element.remove();
										}
									}}
									config={{
										extraPlugins: [uploadPlugin],
										mediaEmbed: {
											previewsInData: true,
										},
									}}
									editor={DecoupledEditor}
									data={dataNew.html_content}
									onChange={(event, editor) => {
										const data = editor.getData();
										setFieldValue("html_content", data);
										setHtmlContent(data);
									}}
								/>
							</div>
							<button
								type="submit"
								style={{ display: "none" }}
								ref={btnRef}
								onSubmit={() => handleSubmit()}
							></button>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
}
