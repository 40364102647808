import * as requestFromServer from "./usersCrud";
import { usersSlice, callTypes } from "./usersSlice";

const { actions } = usersSlice;

export const fetchUsers = (queryParams) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAllUsers(queryParams)
		.then((response) => {
			const {
				data,
				_metadata: { total_count },
			} = response.data.data;
			dispatch(
				actions.usersFetched({ totalCount: total_count, entities: data })
			);
		})
		.catch((error) => {
			error.clientMessage = "Can't find users";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchUser = (id) => (dispatch) => {
	if (!id) {
		return dispatch(actions.userFetched({ userForEdit: undefined }));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getUserById(id)
		.then((response) => {
			const user = response.data;
			dispatch(actions.userFetched({ userForEdit: user }));
		})
		.catch((error) => {
			error.clientMessage = "Can't find user";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteUser = (id) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.deleteUser(id)
		.then((response) => {
			dispatch(actions.userDeleted({ id }));
		})
		.catch((error) => {
			error.clientMessage = "Can't delete user";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const changeActiveUser = (id) => (dispatch) =>
	new Promise((resolve, reject) => {
		dispatch(actions.startCall({ callType: callTypes.action }));
		return requestFromServer
			.changeActiveUser(id)
			.then((response) => {
				dispatch(actions.userActived());
				resolve(response);
			})
			.catch((error) => {
				error.clientMessage = "Can't change active user";
				dispatch(actions.catchError({ error, callType: callTypes.action }));
				reject(error);
			});
	});

export const createUser = (userForCreation) => (dispatch) =>
	new Promise((resolve, reject) => {
		dispatch(actions.startCall({ callType: callTypes.action }));
		return requestFromServer
			.createUser(userForCreation)
			.then((response) => {
				dispatch(actions.userCreated());
				resolve(response);
			})
			.catch((error) => {
				error.clientMessage = "Can't create user";
				dispatch(actions.catchError({ error, callType: callTypes.action }));
				reject(error);
			});
	});

export const updateUser = (user) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateUser(user)
		.then(() => {
			dispatch(actions.userUpdated({ user }));
		})
		.catch((error) => {
			error.clientMessage = "Can't update user";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const updateUsersStatus = (ids, status) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateStatusForUsers(ids, status)
		.then(() => {
			dispatch(actions.usersStatusUpdated({ ids, status }));
		})
		.catch((error) => {
			error.clientMessage = "Can't update users status";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteUsers = (ids) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.deleteUsers(ids)
		.then(() => {
			dispatch(actions.usersDeleted({ ids }));
		})
		.catch((error) => {
			error.clientMessage = "Can't delete users";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};
