import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";
import { UsersPage } from "./modules/Users/pages/UsersPage";
import { NewsPage } from "./modules/News/pages/NewsPage";
import { NewEdit } from "./modules/News/pages/new-edit/NewEdit";
// import { EventsPage } from "./modules/Events/pages/EventsPage";
// import { EventEdit } from "./modules/Events/pages/event-edit/EventEdit";
// import { GuidesPage } from "./modules/Guides/pages/GuidesPage";
// import { GuideEdit } from "./modules/Guides/pages/guide-edit/GuideEdit";
// import { AnnouncementsPage } from "./modules/Announcements/pages/AnnouncementsPage";
// import { AnnouncementEdit } from "./modules/Announcements/pages/announcement-edit/AnnouncementEdit";

// const GoogleMaterialPage = lazy(() => import("./modules/GoogleMaterialExamples/GoogleMaterialPage"));
// const ReactBootstrapPage = lazy(() => import("./modules/ReactBootstrapExamples/ReactBootstrapPage"));
// const ECommercePage = lazy(() => import("./modules/ECommerce/pages/eCommercePage"));
const UserProfilepage = lazy(() => import("./modules/UserProfile/UserProfilePage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const { isAdmin } = useSelector(
    ({ auth }) => ({
      isAdmin: auth?.user?.roles === "admin" || false,
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {isAdmin ? <Redirect exact from="/" to="/users" /> : <Redirect exact from="/" to="/news" />}
        {/* <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} /> */}
        <ContentRoute path="/news/add" component={NewEdit} />
        <ContentRoute path="/news/:id/edit" component={NewEdit} />
        {/* <ContentRoute path="/events/add" component={EventEdit} />
        <ContentRoute path="/events/:id/edit" component={EventEdit} />
        <ContentRoute path="/guides/add" component={GuideEdit} />
        <ContentRoute path="/guides/:id/edit" component={GuideEdit} />
        <ContentRoute path="/announcements/add" component={AnnouncementEdit} />
        <ContentRoute path="/announcements/:id/edit" component={AnnouncementEdit} /> */}
        {/* <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} /> */}
        <Route path="/user-profile" component={UserProfilepage} />
        {isAdmin && <Route path="/users" component={UsersPage} />}
        <Route path="/news" component={NewsPage} />
        {/* <Route path="/events" component={EventsPage} />
        <Route path="/guides" component={GuidesPage} />
        <Route path="/announcements" component={AnnouncementsPage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
