/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };

  const { isAdmin } = useSelector(
    ({ auth }) => ({
      isAdmin: auth?.user?.roles === "admin" || false,
    }),
    shallowEqual
  );

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/users", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {isAdmin && (
          <li className={`menu-item ${getMenuItemActive("/news", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/news">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">News</span>
            </NavLink>
          </li>
        )}
        {/* <li className={`menu-item ${getMenuItemActive("/events", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/events">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Events</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/guides", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/guides">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Guides</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/announcements", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/announcements">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Announcements</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}

        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/* Bootstrap */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}

        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}

        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
