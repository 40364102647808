import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/usersActions";
import { UserEditDialogHeader } from "./UserEditDialogHeader";
import { UserEditForm } from "./UserEditForm";
import { useUsersUIContext } from "../UsersUIContext";

export function UserEditDialog({ id, show, onHide }) {
	// Users UI Context
	const usersUIContext = useUsersUIContext();
	const usersUIProps = useMemo(() => {
		return {
			initUser: usersUIContext.initUser,
			queryParams: usersUIContext.queryParams,
		};
	}, [usersUIContext]);

	// Users Redux state
	const dispatch = useDispatch();
	const { actionsLoading, userForEdit } = useSelector(
		(state) => ({
			actionsLoading: state.users.actionsLoading,
			userForEdit: state.users.userForEdit,
		}),
		shallowEqual
	);

	useEffect(() => {
		// server call for getting User by id
		dispatch(actions.fetchUser(id));
	}, [id, dispatch]);

	// server request for saving user
	const saveUser = (user) => {
		if (!id) {
			// server request for creating user
			dispatch(actions.createUser(user)).then(() => {
				dispatch(
					actions.fetchUsers({
						limit: usersUIProps.queryParams.pageSize,
						page: usersUIProps.queryParams.pageNumber,
					})
				);
				onHide();
			});
		} else {
			// server request for updating user
			// dispatch(actions.updateUser(user)).then(() => onHide());
		}
	};

	return (
		<Modal
			size="lg"
			show={show}
			onHide={onHide}
			aria-labelledby="example-modal-sizes-title-lg"
		>
			<UserEditDialogHeader id={id} />
			<UserEditForm
				saveUser={saveUser}
				actionsLoading={actionsLoading}
				user={userForEdit || usersUIProps.initUser}
				onHide={onHide}
			/>
		</Modal>
	);
}
