export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { statusCode } = error.response.data;
      if ([401].includes(statusCode)) {
        window.location.href = "/logout";
      }
      return Promise.reject(error);
    }
  );
}
