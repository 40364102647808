import React from "react";
import { Route } from "react-router-dom";
import { NewsLoadingDialog } from "./news-loading-dialog/NewsLoadingDialog";
import { NewDeleteDialog } from "./new-delete-dialog/NewDeleteDialog";
// import { NewsDeleteDialog } from "./news-delete-dialog/NewsDeleteDialog";
// import { NewsFetchDialog } from "./news-fetch-dialog/NewsFetchDialog";
// import { NewsUpdateStatusDialog } from "./news-update-status-dialog/NewsUpdateStatusDialog";
import { NewsCard } from "./NewsCard";
import { NewsUIProvider } from "./NewsUIContext";

export function NewsPage({ history }) {
	const newsUIEvents = {
		addNewButtonClick: () => {
			history.push("/news/add");
		},
		openEditNewPage: (id) => {
			history.push(`/news/${id}/edit`);
		},
		openDeleteNewDialog: (id) => {
			history.push(`/news/${id}/delete`);
		},
		openDeleteNewsDialog: () => {
			history.push(`/news/deleteNews`);
		},
		openFetchNewsDialog: () => {
			history.push(`/news/fetch`);
		},
		openUpdateNewsStatusDialog: () => {
			history.push("/news/updateStatus");
		},
	};

	return (
		<NewsUIProvider newsUIEvents={newsUIEvents}>
			<NewsLoadingDialog />
			{/* <Route path="/news/deleteNews">
        {({ history, match }) => (
          <NewsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/news");
            }}
          />
        )}
      </Route> */}
			<Route path="/news/:id/delete">
				{({ history, match }) => (
					<NewDeleteDialog
						show={match != null}
						id={match && match.params.id}
						onHide={() => {
							history.push("/news");
						}}
					/>
				)}
			</Route>
			{/* <Route path="/news/fetch">
        {({ history, match }) => (
          <NewsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/news");
            }}
          />
        )}
      </Route> 
       <Route path="/news/updateStatus">
        {({ history, match }) => (
          <NewsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/news");
            }}
          />
        )}
      </Route> */}
			<NewsCard />
		</NewsUIProvider>
	);
}
