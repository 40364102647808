// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/newsActions";
import * as uiHelpers from "../NewsUIHelpers";
import {
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useNewsUIContext } from "../NewsUIContext";
import { defaultsDeep } from "lodash";

export function NewsTable() {
	// News UI Context
	const newsUIContext = useNewsUIContext();
	const newsUIProps = useMemo(() => {
		return {
			ids: newsUIContext.ids,
			setIds: newsUIContext.setIds,
			queryParams: newsUIContext.queryParams,
			setQueryParams: newsUIContext.setQueryParams,
			openEditNewPage: newsUIContext.openEditNewPage,
			openDeleteNewDialog: newsUIContext.openDeleteNewDialog,
		};
	}, [newsUIContext]);

	// Getting curret state of news list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({ currentState: state.news }),
		shallowEqual
	);
	const { totalCount, entities, listLoading } = currentState;
	// News Redux state
	const dispatch = useDispatch();
	useEffect(() => {
		// clear selections list
		newsUIProps.setIds([]);
		// server call by queryParams
		dispatch(
			actions.fetchNews({
				limit: newsUIProps.queryParams.pageSize,
				page: newsUIProps.queryParams.pageNumber,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newsUIProps.queryParams, dispatch]);

	const changeIsPublished = (item) => {
		dispatch(
			actions.updatePublished(
				defaultsDeep(
					{ is_published: !item.is_published },
					{ slug: item.slug }
				)
			)
		).then(() => {
			dispatch(
				actions.fetchNews({
					limit: newsUIProps.queryParams.pageSize,
					page: newsUIProps.queryParams.pageNumber,
				})
			);
		});
	};

	// Table columns
	const columns = [
		{
			dataField: "title",
			text: "Title",
		},
		{
			dataField: "liked",
			text: "Liked",
		},
		{
			dataField: "viewer",
			text: "Viewer",
		},
		{
			dataField: "tag",
			text: "Tag",
		},
		{
			dataField: "createdAt",
			text: "created Date",
			formatter: columnFormatters.CreatedDateColumnFormatter,
		},
		{
			dataField: "is_published",
			text: "Published",
			formatter: columnFormatters.PublishedColumnFormatter,
		},
		{
			dataField: "action",
			text: "Actions",
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				openEditNewPage: (id) => {
					dispatch(
						actions.savePageNumber(
							newsUIProps.queryParams.pageNumber
						)
					);
					newsUIProps.openEditNewPage(id);
				},
				openDeleteNewDialog: newsUIProps.openDeleteNewDialog,
				changeIsPublished,
			},
			classes: "text-right pr-0",
			headerClasses: "text-right pr-3",
			style: {
				minWidth: "100px",
			},
		},
	];
	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: newsUIProps.queryParams.pageSize,
		page: newsUIProps.queryParams.pageNumber,
	};

	return (
		<>
			<PaginationProvider
				pagination={paginationFactory(paginationOptions)}
			>
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}
						>
							<BootstrapTable
								wrapperClasses="table-responsive"
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								bordered={false}
								remote
								keyField="slug"
								data={entities === null ? [] : entities}
								columns={columns}
								onTableChange={getHandlerTableChange(
									newsUIProps.setQueryParams
								)}
								{...paginationTableProps}
							>
								<PleaseWaitMessage entities={entities} />
								<NoRecordsFoundMessage entities={entities} />
							</BootstrapTable>
						</Pagination>
					);
				}}
			</PaginationProvider>
		</>
	);
}
