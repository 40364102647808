import axios from "axios";

export const CUSTOMERS_URL = "api/users";
export const API_URL = process.env.REACT_APP_API_URL;

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
	return axios.post(`${API_URL}api/cms/users/signup`, user);
}

// READ
export function getAllUsers(queryParams) {
	return axios.post(`${API_URL}api/cms/users/getlist`, queryParams);
}

export function getUserById(userId) {
	return axios.get(`${CUSTOMERS_URL}/${userId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
	return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
	return axios.put(`${CUSTOMERS_URL}/${user.id}`, { user });
}

// UPDATE Status
export function updateStatusForUsers(ids, status) {
	return axios.post(`${CUSTOMERS_URL}/updateStatusForUsers`, {
		ids,
		status,
	});
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
	return axios.delete(`${CUSTOMERS_URL}/${userId}`);
}

// PUT => change active the user from the server
export function changeActiveUser(params) {
	return axios.put(`${API_URL}api/cms/users/changeactive`, params);
}

// DELETE Users by ids
export function deleteUsers(ids) {
	return axios.post(`${CUSTOMERS_URL}/deleteUsers`, { ids });
}
