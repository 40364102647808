/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../_redux/newsActions";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { NewEditForm } from "./NewEditForm";
// import { Specifications } from "../new-specifications/Specifications";
// import { SpecificationsUIProvider } from "../new-specifications/SpecificationsUIContext";
import { useSubheader } from "../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { cloneDeep, defaultsDeep, get, isObject } from "lodash";
// import { RemarksUIProvider } from "../new-remarks/RemarksUIContext";
// import { Remarks } from "../new-remarks/Remarks";
import { ToastContainer, toast } from "react-toastify";

const initNew = {
	tag: "news",
	slug: "",
	title: "",
	description: "",
	cover_image_name: "",
	html_content: "",
	is_published: false,
};

export function NewEdit({
	history,
	match: {
		params: { id },
	},
}) {
	// Subheader
	const suhbeader = useSubheader();

	const [title, setTitle] = useState("");
	const dispatch = useDispatch();
	// const layoutDispatch = useContext(LayoutContext.Dispatch);
	const { actionsLoading, newForEdit } = useSelector(
		(state) => ({
			actionsLoading: state.news.actionsLoading,
			newForEdit: state.news.newForEdit,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(actions.fetchNew(id));
	}, [id, dispatch]);

	useEffect(() => {
		let _title = id ? "Edit new" : "Add New";
		setTitle(_title);
		suhbeader.setTitle(_title);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newForEdit, id]);

	const getCoverImageName = async (file) => {
		let fileName = "";
		if (isObject(file)) {
			if (newForEdit && newForEdit.cover_image_name) {
				dispatch(actions.deleteFile(newForEdit.cover_image_name));
			}
			const formData = new FormData();
			formData.append("file", file);
			const resFile = await dispatch(actions.uploadFile(formData));
			return resFile.data.data.images[0].filename;
		}
		return fileName;
	};

	const saveNew = async (values, file) => {
		let cover_image_name = await getCoverImageName(file);

		let params = cloneDeep(values);
		if (cover_image_name) {
			params = defaultsDeep({ cover_image_name }, values);
		}

		if (!id) {
			dispatch(actions.createNew(params)).then(
				() => backToNewsList(),
				(error) => {
					if (error.response) {
						toast.error(
							get(error.response, "data.data.message", "")
						);
					}
				}
			);
		} else {
			dispatch(actions.updateNew(params)).then(
				() => backToNewsList(),
				(error) => {
					if (error.response) {
						toast.error(
							get(error.response, "data.data.message", "")
						);
					}
				}
			);
		}
	};

	const btnRef = useRef();
	const saveNewClick = () => {
		if (btnRef && btnRef.current) {
			btnRef.current.click();
		}
	};

	const backToNewsList = () => {
		history.push(`/news`);
	};

	return (
		<Card>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{actionsLoading && <ModalProgressBar />}
			<CardHeader title={title}>
				<CardHeaderToolbar>
					<button
						type="button"
						onClick={backToNewsList}
						className="btn btn-light"
					>
						<i className="fa fa-arrow-left"></i>
						Back
					</button>
					{`  `}
					<button
						type="submit"
						className="btn btn-primary ml-2"
						onClick={saveNewClick}
					>
						Save
					</button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<div className="mt-5">
					<NewEditForm
						actionsLoading={actionsLoading}
						new={id ? newForEdit || initNew : initNew}
						btnRef={btnRef}
						saveNew={saveNew}
					/>
				</div>
			</CardBody>
		</Card>
	);
}
